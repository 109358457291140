import './css/main.css';

import { TimelineMax, TweenMax, Linear } from 'gsap';

import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
import imagesLoaded from 'imagesloaded';

// import SuperMarquee from 'sp-supermarquee';

import Zooming from 'zooming';

import { defineCustomElements } from 'vidstack/elements';

defineCustomElements();

    // let vidPlayers = document.querySelectorAll('media-player');
    // if (vidPlayers.length > 0) {
    //     let vd;
    //     for (vd=0; vd < vidPlayers.length; vd++) {
    //         vidPlayers[vd].addEventListener('provider-change', (event) => {
    //             const provider = event.detail;
    //             if (provider?.type === 'hls') {
    //                 console.log('HLS config change');
    //                 // provider.library = () => import('hls.js');
    //                 provider.config = { startLevel: 7 };
    //             };
    //         });
  
  
    //         vidPlayers[vd].addEventListener('hls-level-switching', (event) => {
    //           // console.log(event.detail)
    //         });
    //     };
    // };

const zooming = new Zooming({
    bgColor: "#101010",
    onClose: function (target) {
        target.classList.add('hidden');
        target.classList.add('opacity-0');
    },
    onBeforeOpen: function (target) {
        target.classList.remove('hidden');
    },
    onOpen: function (target) {
        target.classList.remove('opacity-0');
    },
})
// zooming.listen();

// zooming.listen('.img-zoomable')

let zoomImgs = document.getElementsByClassName('img-zoomable');
if (zoomImgs.length > 0) {
    let z;
    for (z=0; z < zoomImgs.length; z++) {
        let id = zoomImgs[z].getAttribute('data-id');
        let targetName = 'zoom-' + id;
        let targetImg = document.getElementById(targetName);
        document.body.appendChild(targetImg);
        zoomImgs[z].addEventListener('click', (event) => {
            
            console.log(targetImg)
            // targetImg.classList.remove('hidden');
            zooming.open(targetImg);
        })
    }
}



window.onscroll = e => import(/* webpackChunkName: "progressBar" */ "./js/components/progressBar.js").then(module => {
    const progressBar = module.default;
    progressBar();
});


// document.fonts.ready
//   .then(() => {
// document.addEventListener('DOMContentLoaded', (event) => {

imagesLoaded( '.img-zoomable', function() {

    // import(/* webpackChunkName: "aspectFix" */ "./js/components/aspectFix.js").then(module => {
    //     const aspect = module.default;
    //     aspect();
    // });


    // let marqCont = document.getElementById('marqeeContent1');
    // const mySuperMarquee = new SuperMarquee(
    //     document.getElementById( "marquee1" ), {
    //         "type" : "horizontal",
    //   "speed" : "medium",
    //   "direction" : "ltr",
    //   "mode" : "continuous",
    //   "content": marqCont.innerHTML
    //     }
    // );
    
    // let marqCont2 = document.getElementById('marqeeContent2');
    // const mySuperMarquee2 = new SuperMarquee(
    //     document.getElementById( "marquee2" ), {
    //         "type" : "horizontal",
    //   "speed" : "medium",
    //   "direction" : "rtl",
    //   "mode" : "continuous",
    //   "content": marqCont2.innerHTML
    //     }
    // );

let offBlack = '#101010';
let orange = '#EC7F00';

// (min-width: 768px)
if (window.innerWidth > 768) {

let controller = new ScrollMagic.Controller();

// --------------------------- Two column — Text + Images ---------------------------

let twoColumnTextImage = document.getElementsByClassName('twoColumn-textImage');
let i;
for (i = 0; i < twoColumnTextImage.length; i++) {
    let id = twoColumnTextImage[i].getAttribute("data-id");
    let elementName = '#twoColumn-textImage-' + id;
    let elementId = 'twoColumn-textImage-' + id;
    let element = document.getElementById(elementId);
    let gridName = 'twoColumnVertical_grid-' + id;
    let twoColumnVertical_grid = document.getElementById(gridName);
    let elementHeight = element.clientHeight;
    console.log( id + "    Element Height: " + elementHeight);

    let textIdName = 'twoColumn-textImage-Text-' + id;
    let imageIdName = 'twoColumn-textImage-Image-' + id;
    let textElem = document.getElementById(textIdName);
    let imageElem = document.getElementById(imageIdName);
    let textHeight = textElem.offsetHeight;
    let imageHeight = imageElem.offsetHeight;

    console.log('imageHeight: ' + imageHeight + '  textHeight: ' + textHeight);

    new ScrollMagic.Scene({
        duration: elementHeight + 'px',
        triggerElement: elementName,
        triggerHook: 0.5,
                })
                .on("enter", function (i) {
                    twoColumnVertical_grid.classList.remove('hidden');
                    setTimeout(function() {twoColumnVertical_grid.classList.remove('opacity-0')}, 200);
                    // document.body.style.backgroundColor = offBlack;
                })
                .on("leave", function (i) { 
                    twoColumnVertical_grid.classList.add('opacity-0');
                    setTimeout(function() {twoColumnVertical_grid.classList.add('hidden')}, 350);
                })
                // .addIndicators({name: "Two column text image" })
                .addTo(controller)

    if (textHeight > imageHeight) {
        imageElem.classList.add('sticky', 'top-0');
        new ScrollMagic.Scene({
            duration: textHeight + 'px',
            triggerElement: elementName,
            triggerHook: 0.5,
                    })

                    .setTween(imageElem, {y: "-" + (imageHeight * 0.3) + "px" , ease: "none" })
                    // .addIndicators({name: "Two column text image" })
                    .addTo(controller)
    } else {
        textElem.classList.add('sticky', 'top-0');
        new ScrollMagic.Scene({
            duration: imageHeight + 'px',
            triggerElement: elementName,
            triggerHook: 0.05,
                    })
                    .setTween(textElem, {y: "-" + (textHeight * 0.5) + "px" , ease: "none" })
                    // .addIndicators({name: "Two column text image" })
                    .addTo(controller)
    }
    
};



// --------------------------- Sideways scroll ---------------------------

    let sidewaysImage = document.getElementsByClassName('sidewaysImages');
    let j;
    for (j = 0; j < sidewaysImage.length; j++) {
        let id = sidewaysImage[j].getAttribute('data-id');
        let elementName = 'sidewaysImages-' + id;
        let scrollmagicElementName = '#sidewaysImages-' + id;
        let outerElement = document.getElementById(elementName);
        let contentDivName = 'sidewaysContent-' + id;
        let scrollmagicContentName = '#sidewaysContent-' + id;
        let contentDiv = document.getElementById(contentDivName);
        let contentWidth = contentDiv.offsetWidth;

        let windowWidth = Math.round(window.innerHeight);

        let scrollDuration = contentWidth - windowWidth;

        let scrollTween = contentWidth - (windowWidth * 1.5);

        console.log('Window width: ' + windowWidth);
        console.log('Content width: ' + contentWidth);

        outerElement.style.height = contentWidth + 'px';
        console.log('Sideways width: ' + contentWidth)
        new ScrollMagic.Scene({
            duration: scrollDuration + 'px',
            triggerElement: scrollmagicElementName,
            triggerHook: 0,
        })
        .setTween(scrollmagicContentName, {x: "-" + scrollTween + "px" , ease: "none" })
        // .addIndicators({name: "Two column text image" })
        .addTo(controller)
    }

// --------------------------- Quote ---------------------------

let quote = document.getElementsByClassName('quote');
let q;
for (q=0; q < quote.length; q++) {
    let id = quote[q].getAttribute('data-id');
    let colour = quote[q].getAttribute('data-bg');
    let scrollId = '#quote-' + id;
    let idName = 'quote-' + id;

    let element = document.getElementById(idName);
    let height = element.offsetHeight + 'px';
    if (colour == 1) {
        new ScrollMagic.Scene({
            duration: height,
            triggerElement: scrollId,
            triggerHook: 0.5,
                    })
                    .on("enter", function (i) {
                        document.body.style.backgroundColor = orange;
                    })
                    .on("leave", function (i) { 
                        document.body.style.backgroundColor = offBlack;
                    })
                    .on("end", function (i) { 
                    })
                    // .addIndicators({name: "Quote" })
                    .addTo(controller)
    }
}


}; // --- End screen width if statement

});








// --------------------------- Artist image hover ---------------------------


// let artists = document.getElementsByClassName('artistName');
// if (artists.length > 0) {
//     let a;
//     for (a=0; a < artists.length; a++) {
//         artists[a].addEventListener('mouseover', (event) => {
//             let id = event.target.getAttribute('data-id');
//             let imageId = 'artistImage-' + id;
//             let image = document.getElementById(imageId);
//             image.classList.remove('opacity-0');
//             image.style.top = event.clientY + 'px';
//             image.style.left = event.clientX + 'px';
//             console.log(event);

//         });

//         artists[a].addEventListener('mouseleave', resetArtistImages);
        

//     }
// }

// function resetArtistImages() {
//     let artistImages = document.getElementsByClassName('artistImage');
//     let ai;
//     for (ai = 0; ai < artistImages.length; ai ++) {
//         artistImages[ai].classList.add('opacity-0');
//     };
// };